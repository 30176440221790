

























import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { LoadingStatusType } from "@/enums/enums";
const StoreAccountingFrim = namespace("AccountingFrim");
@Component
export default class ModalDeleteAccountingFirm extends Vue {
  @Prop({ required: true }) readonly id!: any;
  @Prop({ required: true }) readonly name!: any;
  @Prop({ required: true }) readonly code!: any;
  private isShowModal = true;
  @StoreAccountingFrim.Action
  private DoDeleteAccountingFirm!: (input: any) => void;

  @StoreAccountingFrim.Action
  private DoGetAccountingFirmTable!: (input: any) => void;

  @StoreAccountingFrim.Getter
  private getDeleteAccountingFirmsLoadingStatus!: LoadingStatusType;

  @Watch("isShowModal")
  showModalChanged(newVal: boolean) {
    if (!newVal) {
      this.Cancel();
    }
  }

  @Watch("getDeleteAccountingFirmsLoadingStatus", { immediate: true, deep: true })
  getDeleteAccountingFirmsLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      alert(`Delete ${this.code} Successfully.`);
      const routeData = this.$router.resolve({
        name: "accountingFirmTable",
      });
      window.location.href = routeData.href;
      this.Cancel();
    } else if (newVal === LoadingStatusType.Fail) {
      this.Cancel();
    }
  }

  public Cancel() {
    this.$emit("update:isShowModal", false);
  }
  public Confirm() {
    this.DoDeleteAccountingFirm({ id: this.id });
  }
}
